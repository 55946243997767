






















































import KCard from '@/@core/components/other/KCard.vue';
import KText from '@/@core/components/typography/KText.vue';
import { isMobile } from '@/@core/helpers/useBreakpoint';
import {
  IndustryStatistic,
  useGenerateStatistics
} from '@/modules/landing/helpers/generate-statistics';
import {
  computed,
  defineComponent,
  PropType,
  ref
} from '@vue/composition-api';
import StatisticCard from './StatisticCard.vue';
import StatisticChart from './StatisticChart.vue';

export default defineComponent({
  components: { StatisticCard, StatisticChart, KCard, KText },
  name: 'EmployeeDashboard',
  props: {
    loading: {
      type: Boolean,
      required: false
    },
    statisticData: {
      type: Object as PropType<IndustryStatistic>,
      required: true
    }
  },
  setup(props) {
    const generateStatistics = () => {
      const {
        total,
        verified,
        unverified,
        large,
        small,
        medium,
        products
      } = useGenerateStatistics(props.statisticData);

      const statistics = [
        total,
        verified,
        unverified,
        large,
        small,
        medium,
        products
      ];

      return statistics;
    };

    const statistics = computed(() => generateStatistics());

    return {
      isMobile,
      statistics
    };
  }
});
